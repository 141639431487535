import { onChildAdded, onChildRemoved, onValue, ref } from "@firebase/database";
import React, { createContext, useEffect, useState } from "react";
import { IUIConfigWithValues } from "./config";
import { db } from "./firebase";

export type TimedAction = {
  type: string;
  clientAction: string;
  value: number;
  utcDate: string;
  date: Date;
  ref: string;
};

interface IAppContext {
  state: IUIConfigWithValues | null;
  timedActions: TimedAction[]
}

export const AppContext = createContext<IAppContext>({
  state: null,
  timedActions: []
});

export function findValueInState(state: IUIConfigWithValues, uid: string) {
  for (let f of state.functions) {
    for (let dp of f.dataPoints) {
      if (dp.uid === uid) return dp.value;
    }
  }

  return null;
}

export const AppContextProvider: React.FunctionComponent = (props) => {
  const [appState, setAppState] = useState<IAppContext>({
    state: null,
    timedActions: []
  });

  useEffect(
    () =>
      onValue(ref(db, "state"), (snap) => {
        setAppState(appState => ({ ...appState, state: snap.val() }));
      }),
    []
  );

  

  useEffect(
    () =>
      onChildAdded(ref(db, "timed_actions"), (snap) => {
        setAppState((appState) => ({
          ...appState,
          timedActions: [
            ...appState.timedActions,
            {
              ...snap.val(),
              date: new Date(snap.val().utcDate),
              ref: snap.ref.toString(),
            },
          ],
        }));
      }),
    []
  );

  useEffect(
    () =>
      onChildRemoved(ref(db, "timed_actions"), (snap) => {
        setAppState((appState) => {
          let actionsCopy = [...appState.timedActions];
          let i = actionsCopy.findIndex((a) => snap.ref.toString() === a.ref);
          actionsCopy.splice(i, 1);
          return {
            ...appState,
            timedActions: actionsCopy,
          };
        });
      }),
    []
  );

//   useEffect(() => {
//     console.log(appState);
//   }, [appState]);

  return (
    <AppContext.Provider value={appState}>{props.children}</AppContext.Provider>
  );
};
