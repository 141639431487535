// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  push,
  refFromURL,
  remove,
} from "firebase/database";

import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAabDprsTcUE2hoOYbJ47Wst5yCBR2ppAk",
  authDomain: "edli-lights.firebaseapp.com",
  databaseURL:
    "https://edli-lights-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "edli-lights",
  storageBucket: "edli-lights.appspot.com",
  messagingSenderId: "1005417174766",
  appId: "1:1005417174766:web:3498914af00470638885ff",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const db = getDatabase(firebaseApp);
export const auth = getAuth(firebaseApp);


const DEBUG = localStorage.getItem("DEBUG") && localStorage.getItem("DEBUG") === 'DEBUG';

if(DEBUG) console.warn("DEBUG mode! To exit clear DEBUG key in localstorage.")

export const pushAction = (action: {
  clientAction: string;
  value: number;
}) => {

  if(DEBUG) return console.warn("No action executed, debug mode", action);

  const actionsRef = ref(db, "actions");
  return push(actionsRef, action);
};

export const pushTimedAction = (action: {
  clientAction: string;
  value: number;
  utcDate: string;
}) => {

  if(DEBUG) return console.warn("No timed action executed, debug mode", action);

  const actionsRef = ref(db, "timed_actions");
  return push(actionsRef, action);
};

export const cancelTimedAction = (actionRef: string) => {

  if(DEBUG) return console.warn("No timed action cancelled, debug mode", actionRef);

  return remove(refFromURL(db, actionRef));
};
