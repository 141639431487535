export const DATA_POINT_MAP = {
  rwy1: "a024",
  rwy2: "a02d",
  rwy3: "a027",
  rwy4: "a02e",
  rwy5: "a02f",
  rwyOff: "a00c",
  papi1: "a00e",
  papi2: "a00g",
  papi3: "a00i",
  papi4: "a00k",
  papi5: "a00m",
  papiOff: "a02n",
  thr1: "a02q",
  thr2: "a02s",
  thr3: "a02u",
  thrOff: "a02v",
  twy1: "a00u",
  twy2: "a00w",
  twy3: "a00y",
  twyOff: "a010",
  abnMain: "a01o",
  abnAux: "a01q",
  app11White: "a01s",
  app11Red: "a01u",
  oblMan: "a032",
  segelflug: "a034",
  allOff: "a03b"
};

export const ActivationMap = {
  rwy1: 1,
  rwy2: 0,
  rwy3: 1,
  rwy4: 0,
  rwy5: 1,
  rwyOff: 0,
  papi1: 1,
  papi2: 0,
  papi3: 1,
  papi4: 0,
  papi5: 1,
  papiOff: 0,
  thr1: 1,
  thr2: 0,
  thr3: 1,
  thrOff: 0,
  twy1: 1,
  twy2: 0,
  twy3: 1,
  twyOff: 0,
  allOff: 0
};

// Prod?
// export const NEGATION_ARRAY = [
//   "rwy2",
//   "rwy4",
//   "rwyOff",
//   "papi2",
//   "papi4",
//   "papiOff",
//   "twy2",
//   "twyOff",
//   "thr2",
//   "thrOff",
// ];

//export const NEGATION_ARRAY: string[] = [];

export const TIMED_ACTION_VALUES = {
  rwyOff: 0,
  papiOff: 0,
  twyOff: 0,
  thrOff: 0,
  abnMain: 0,
  allOff: 0
};

export interface IUIConfigWithValues {
  functions: {
    channelType: string;
    dataPoints: {
      name: string;
      uid: string;
      value: number;
    }[];
    displayName: string;
    functionType: string;
    uid: string;
  }[];
  uid: string;
}
