import React, { useContext, useEffect, useState } from "react";
import "./Button.css";
import moment from "moment";
import useInterval from "./useInterval";
import { cancelTimedAction, pushTimedAction } from "./firebase";
import { AppContext, TimedAction } from "./AppContext";
import { TIMED_ACTION_VALUES } from "./config";

interface IButtonProps {
  name: string;
  actionName: string;
}

export const formatDuration = (durationInMs: number) => {
  if (durationInMs < 0) return "00:00";

  const hours = Math.floor(moment.duration(durationInMs).asHours()) || "00";

  if (hours === 0 || hours === "00") {
    return moment.utc(durationInMs).format("mm:ss");
  } else {
    return hours + ":" + moment.utc(durationInMs).format("mm:ss");
  }
};

const TimedButton: React.FunctionComponent<IButtonProps> = (props) => {
  const appContext = useContext(AppContext);
  const [active, setActive] = useState(false);
  const [timedAction, setTimedAction] = useState<TimedAction | null>(null);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    let actionOnServer = appContext.timedActions.find(
      (a) => a.clientAction.toLowerCase() === props.actionName.toLowerCase()
    );
    setTimedAction(actionOnServer ? actionOnServer : null);
  }, [appContext.timedActions, props.actionName]);

  useEffect(() => {
    setActive(!!timedAction);
  }, [timedAction]);

  useInterval(
    () => {
      if (timedAction) {
        setTimeLeft(timedAction.date.valueOf() - new Date().valueOf());
      }
    },
    timedAction ? 200 : null
  );

  return (
    <div
      className={`button timed ${active ? "active" : ""}`}
      onClick={(e) => {
        if (active) {
          cancelTimedAction(timedAction!.ref);
        } else {
          let minutes = prompt("Wie viele Minuten?", "10");
          if (minutes) {
            let m = parseFloat(minutes.replace(",", "."));
            const newState = !active;
            setActive(newState);
            pushTimedAction({
              clientAction: props.actionName,
              // TODO check
              // This is for negation of Values
              // value: newState ? 1 : 0,
              //value: NEGATION_ARRAY.includes(props.actionName) ? 0 : 1,
              // Non negated values need to be 0, other need to be too?
              value: (TIMED_ACTION_VALUES as any)[props.actionName],
              utcDate: moment().add(m, "minutes").toDate().toISOString(),
            });
          }
        }
      }}
    >
      {timedAction && active ? (
        <div>{formatDuration(timeLeft)}</div>
      ) : (
        <div>
          <ion-icon name="time"></ion-icon>
        </div>
      )}
      {/* {!timedAction && <div><ion-icon name="time"></ion-icon></div>} */}
    </div>
  );
};

export default TimedButton;
