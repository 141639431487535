import React, { useState } from "react";

import { signInWithEmailAndPassword } from "firebase/auth";
import "./LoginForm.css";
import { auth } from "./firebase";

const LoginForm: React.FunctionComponent = (props) => {
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className="login">
      <h3>Bitte einloggen</h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          signInWithEmailAndPassword(auth, mail, password)
            .then(async (res) => {
              await auth.updateCurrentUser(res.user);
              window.location.reload();
            })
            .catch((err) => {
              alert(err.message);
            });
        }}
      >
        <div>
          <label htmlFor="">E-Mail</label>
          <input
            type="text"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
            placeholder="E-Mail"
          />
        </div>
        <div>
          <label htmlFor="">Passwort</label>
          <input
            type="password"
            value={password}
            placeholder="Passwort"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="sign-in-button">
          Anmelden
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
