import React, { useEffect, useState } from "react";
import "./App.css";
import LightBoard from "./LightBoard";

import { auth } from "./firebase";
import LoginForm from "./LoginForm";

function App() {
  const [currentUser, setCurrentUser] = useState(auth.currentUser);

  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentUser(auth.currentUser);
      setShow(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [show]);

  useEffect(
    () =>
      auth.onAuthStateChanged(() => {
        setCurrentUser(auth.currentUser);
      }),
    []
  );

  if (!show) return null;

  if (!!currentUser) {
    return <LightBoard></LightBoard>;
  } else {
    return <LoginForm></LoginForm>;
  }
}

export default App;
