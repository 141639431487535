import { signOut } from "@firebase/auth";
import { get, ref } from "@firebase/database";
import React, { useEffect, useState } from "react";
import "./App.css";
import Button from "./Button";
import { auth, db, pushAction } from "./firebase";
import TimedButton from "./TimedButton";

function LightBoard() {

  const [debugMode, setDebugMode] = useState(false);

  useEffect(() => {
    get(ref(db, 'debugMode')).then(data => setDebugMode(data.val())).catch(err => console.log(err));
  });

  useEffect(() => {
    pushAction({
      clientAction: "SYNC",
      value: -1,
    });
  }, []);

  return (
    <div>
      {debugMode && <div className="debug">
        <b>Achtung! Die Seite befindet sich im Wartungsmodus!</b>
        <div>Die Funktionalität kann beeinträchtigt sein.</div>
      </div> }
      <div id="button-grid">
        <div>
          {[5, 4, 3, 2, 1].map((n) => (
            <Button
              key={`RWY ${n}`}
              name={`RWY ${n}`}
              actionName={`rwy${n}`}
            ></Button>
          ))}
          <div className="button-group">
            <Button name="RWY Aus" actionName="rwyOff" showSun={false}></Button>
            <TimedButton name="Aus" actionName="rwyOff"></TimedButton>
          </div>
        </div>
        <div>
          {[5, 4, 3, 2, 1].map((n) => (
            <Button
              key={`PAPI ${n}`}
              name={`PAPI ${n}`}
              actionName={`papi${n}`}
            ></Button>
          ))}
          <div className="button-group">
            <Button
              name="PAPI Aus"
              actionName="papiOff"
              showSun={false}
            ></Button>
            <TimedButton name="Aus" actionName="papiOff"></TimedButton>
          </div>
        </div>
        <div>
          {[3, 2, 1].map((n) => (
            <Button
              key={`TWY ${n}`}
              name={`TWY ${n}`}
              actionName={`twy${n}`}
            ></Button>
          ))}
          <div className="button-group">
            <Button name="TWY Aus" actionName="twyOff" showSun={false}></Button>
            <TimedButton name="Aus" actionName="twyOff"></TimedButton>
          </div>
        </div>
        <div>
          {[3, 2, 1].map((n) => (
            <Button
              key={`THR ${n}`}
              name={`THR ${n}`}
              actionName={`thr${n}`}
            ></Button>
          ))}
          <div className="button-group">
            <Button name="THR Aus" actionName="thrOff" showSun={false}></Button>
            <TimedButton name="Aus" actionName="thrOff"></TimedButton>
          </div>
        </div>
      </div>
      <hr />
      <div id="button-grid">
        <div>
          <div className="button-group">
            <Button name="ABN Main" actionName="abnMain"></Button>
            <TimedButton name="Aus" actionName="abnMain"></TimedButton>
          </div>
        </div>
        <div>
          <Button name="ABN AUX" actionName="abnAux"></Button>
        </div>
        <div>
          <Button name="APP 11 wht" actionName="app11White"></Button>
          <Button name="OBL Man" actionName="oblMan"></Button>
        </div>
        <div>
          <Button name="APP 11 red" actionName="app11Red"></Button>
          <Button name="OBL Auto" actionName="obl-auto"></Button>
        </div>
      </div>
      <hr />
      <div id="button-grid">
        <div>
          <div className="button-group">
            <Button
              name="Zentral Aus"
              actionName="allOff"
              showSun={false}
            ></Button>
            <TimedButton
              name="Zentral Aus"
              actionName="allOff"
            ></TimedButton>
          </div>
        </div>
        <div></div>
        <div></div>
        <div>{/* <Button name="Störungen" actionName=""></Button> */}</div>
      </div>

      <div className="sign-out" onClick={() => signOut(auth)}>
        Abmelden
      </div>

      <div
        className="sign-out"
        onClick={() => {
          pushAction({
            clientAction: "SYNC",
            value: -1,
          });
        }}
      >
        Sync
      </div>
    </div>
  );
}

export default LightBoard;
