import React, { useContext, useEffect, useState } from "react";
import "./Button.css";
import { pushAction } from "./firebase";
import { AppContext, findValueInState } from "./AppContext";
import { ActivationMap, DATA_POINT_MAP } from "./config";

interface IButtonProps {
  name: string;
  actionName: string;
  showSun?: boolean;
}


const Button: React.FunctionComponent<IButtonProps> = (props) => {
  const appContext = useContext(AppContext);
  const [active, setActive] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (appContext.state) {
      let uid = (DATA_POINT_MAP as any)[props.actionName];
      if (uid) {
        let val = findValueInState(appContext.state, uid);
        if (val !== null) {
          let activeInState = val === 0 ? false : true;
          setActive((prev) => activeInState);
        } else {
          setActive(false);
        }
      } else {
        setActive(false);
        setDisabled(true);
      }
    }
  }, [appContext.state, props.actionName]);

  return (
    <div
      className={`button ${active ? "active" : ""} ${
        disabled ? "disabled" : ""
      }`}
      onClick={(e) => {
        if (disabled) return alert("Keine Funktion im X1 hinterlegt.");
        const target = e.target as HTMLElement;
        if (target.classList.contains("button")) {
          const newState = !active;

          if ((ActivationMap as any)[props.actionName] !== undefined) {
            if (newState === true) {
              pushAction({
                clientAction: props.actionName,
                value: (ActivationMap as any)[props.actionName],
              });
              setActive(newState);
            } else {
              // Todo .... dirty fix

              pushAction({
                clientAction: props.actionName.replace(/\d/g, "").replace("Off", '') + "Off",
                value: 0,
              });
              setActive(newState);
            }
          } else {
            pushAction({
              clientAction: props.actionName,
              value: newState ? 1 : 0,
            });
            setActive(newState);
          }
        }
      }}
    >
      {props.name}
      <div>
        {props.showSun && (
          <ion-icon
            item-start
            name={active ? "sunny" : "sunny-outline"}
          ></ion-icon>
        )}
      </div>
    </div>
  );
};

Button.defaultProps = {
  showSun: true,
};

export default Button;
